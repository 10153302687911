// import styles
import '../scss/style.scss';
import '../scss/admin.scss';
import '../scss/login.scss';

// import libraries, remove if unused
//import Swiper from 'Swiper';
// to include Swiper modules
import Swiper, {EffectFade, Pagination} from 'Swiper';
import {ZBRangeSlider} from "./libs/range-slider";

// import {
//   animateOnScroll,
//   advancedSticky,
//   parralax,
//   svgPathFill
// } from './libs/antimation'; // import animation functions, don't forget to import styles when using animateOnScroll

/***
 * Get Viewport Dimensions
 ***/
//var viewport;
var vpWidth;

// get vp dimensions
function updateViewportDimensions() {
  let x = window.innerWidth || document.documentElement.clientWidth;
  let y = window.innerHeight || document.documentElement.clientWidth;

  //viewport = {width: x, height: y};
  vpWidth = x;
}

updateViewportDimensions();

// menu toggle
function clickMenuItem(e) {
  e.stopPropagation();
  e.preventDefault();
  e.target.parentNode.classList.add('active');
}

function adjustMenu() {
  const toggleMenu = document.querySelector('.toggleMenu');
  const topNav = document.querySelector('.main-menu');

  if (vpWidth < 1024) {
    if (toggleMenu.classList.contains('active')) {
      topNav.classList.add('active');
      fixbody();
    }

    // add click menu item
    const menuChild = topNav.querySelector('.menu-item-has-children > a');
    if (menuChild) {
      menuChild.removeEventListener('click', clickMenuItem);
      menuChild.addEventListener('click', clickMenuItem);
    }
  } else if (vpWidth >= 1024) {
    topNav.querySelector('li').classList.remove('active');
    topNav.querySelector('li a').removeEventListener('click', clickMenuItem);

    unfixBody();
  }
}

//on window resize event
window.addEventListener(
  'resize',
  throttle(() => {
    updateViewportDimensions();
    adjustMenu();
  }, 75)
);

const bodyElement = document.querySelector('body');
// fix body(when mobile menu is active)
function fixbody() {
  let scrollPosition = window.pageYOffset;

  bodyElement.classList.add('menu-active');
  bodyElement.style.top = '-' + scrollPosition + 'px';
  bodyElement.setAttribute('data-scroll', scrollPosition);
}

// unfix body (when mobile menu untoggled)
function unfixBody() {
  if (bodyElement.classList.contains('menu-active')) {
    const scrollPosition = bodyElement.getAttribute('data-scroll');

    bodyElement.classList.remove('menu-active');
    bodyElement.style.top = 'auto';

    window.scrollTo(0, parseInt(scrollPosition));
  }
}

/***
 *  Page load scripts
 ***/
document.addEventListener('DOMContentLoaded', (event) => {
  document.querySelector('body').classList.add('js-active');

  adjustMenu();

  // Mobile menu toggle
  document.querySelector('.toggleMenu').addEventListener('click', (e) => {
    e.preventDefault();
    const menu = document.querySelector('.main-menu');

    if (menu.classList.contains('active')) {
      menu.classList.remove('active');
      unfixBody();
    } else {
      menu.classList.add('active');
      fixbody();
    }
  });

  const selectors = [
    "iframe[src*='player.vimeo.com']",
    "iframe[src*='vimeo.com']",
    "iframe[src*='youtube.com']",
    "iframe[src*='youtube-nocookie.com']",
    "iframe[src*='kickstarter.com'][src*='video.html']",
    'object',
    'embed',
  ];
  reframe(selectors.join(','));

  // object fit polyfill for IE
  if (!Modernizr.objectfit) {
    document.querySelectorAll('.img-container').forEach((el) => {
      const imgUrl = el.querySelector('img').src;
      if (imgUrl) {
        el.style.backgroundImage = 'url(' + imgUrl + ')';
        el.classList.add('compat-object-fit');
      }
    });
  }
});

/*********************
* SEARCH FILTER
*********************/

const searchFilter = document.querySelector('.search-filter');
if (searchFilter) {

  let filters;
  const typeButtons = document.getElementById('typeButtons');
  const yearRange = document.getElementById('yearRange');

  const iso = new Isotope('.search-result-grid', {
    itemSelector: '.search-result-tile',
    transitionDuration: 0
  });

  if (typeButtons) {
    const filterButtons = document.querySelectorAll('.filter-button');

    filterButtons.forEach((element) => {
      element.addEventListener('click', (e) => {

        if (element.classList.contains('active')) {
          element.classList.remove('active');
        } else {
          element.classList.add('active');
        }

        let filterArray = [];
        let activeFilterButtons = document.querySelectorAll('.filter-button.active');

        activeFilterButtons.forEach((activeElement) => {
          let dataFilter = activeElement.getAttribute('data-filter');
          filterArray.push('.' + dataFilter + '.year-match');
        });

        filters = filterArray;

        let filterString = filters.join(', ');

        if (!filterString) {
          filterString = '.year-match';
        }

        iso.arrange({
          filter: filterString
        });

      });
    });
  }

  if (yearRange) {
    const rangeSlider = new ZBRangeSlider('yearRange');

    rangeSlider.onChange = function (min, max) {

      document.querySelector('.slider-touch-left p').innerHTML = min;
      document.querySelector('.slider-touch-right p').innerHTML = max;

    }

    rangeSlider.didChanged = function (min, max) {

      const searchResultTiles = document.querySelectorAll('.search-result-tile');

      searchResultTiles.forEach((element) => {

        const publishedYear = element.getAttribute('data-publishedYear');

        if (publishedYear >= min && publishedYear <= max) {
          element.classList.add('year-match');
        } else {
          element.classList.remove('year-match');
        }

      });

      let filterString;

      if (filters === undefined) {
        filters = [];
      }

      if (filters.length === 0) {
        filterString = '.year-match';
      } else {
        filterString = filters.join(', ');
      }

      iso.arrange({
        filter: filterString
      });

    }
  }
}

/*********************
* SEARCH FORM
*********************/

const searchBtn = document.getElementById('searchBtn');
const searchForm = document.getElementById('searchForm');
if (searchBtn && searchForm) {

  searchBtn.addEventListener('click', (e) => {
    searchForm.classList.toggle('active');
    document.getElementById('logo').classList.toggle('hidden');
  });

}

/*********************
* ISSUE SLIDER
*********************/

const issueSlider = document.querySelector('.issue-slider-section');
if (issueSlider) {

  const swiperIssue = new Swiper('.issue-slider', {
    modules: [Pagination, EffectFade],
    loop: false,
    slidesPerView: 1,
    speed: 300,

    pagination: {
      el: '.swiper-pagination',
      type: 'bullets'
    },

    effect: 'fade',
    fadeEffect: {
      crossFade: true
    }
  });

  window.addEventListener('resize', (e) => {
    if (vpWidth < 768) {
      swiperIssue.enable();
      swiperIssue.attachEvents();
    } else if (vpWidth > 768) {
      swiperIssue.disable();
      swiperIssue.detachEvents();
    }
  });

  document.addEventListener('DOMContentLoaded', (e) => {
    if (vpWidth >= 768) {
      swiperIssue.disable();
      swiperIssue.detachEvents();
    }
  });

  swiperIssue.on('slideChange', function() {
    if (vpWidth < 768) {
      const currentSlide = swiperIssue.slides[this.realIndex];
      showIssue(currentSlide);
    }
  });

  const issueSlides = document.querySelectorAll('.issue-slide');
  issueSlides.forEach((element) => {
    element.addEventListener('click', (e) => {
      if (vpWidth >= 768) {
        showIssue(element);
      }
    });
  });

}

function showIssue(currentSlide)
{
    const currentSlideImg = currentSlide.querySelector('.img-container');
    const currentIssueId = currentSlideImg.getAttribute('data-issueId');
    const currentIssueTitle = document.querySelector('[data-issueIdTitle="' + currentIssueId + '"]');
    const currentIssueText = document.querySelector('[data-issueIdText="' + currentIssueId + '"]');
    const activeIssueTitle = document.querySelector('.ist-title.active');
    const activeIssueText = document.querySelector('.ist-text.active');

    activeIssueTitle.classList.remove('active');
    activeIssueText.classList.remove('active');

    currentIssueTitle.classList.add('active');
    currentIssueText.classList.add('active');

    if (vpWidth >= 768) {
      const activeIssueImg = document.querySelector('.issue-slide:first-of-type');

      currentSlide.append(activeIssueImg.querySelector('.img-container'));
      activeIssueImg.append(currentSlideImg);
    }
}

/*********************
* SCROLL TOP
*********************/

const scrollTopBtn = document.getElementById('scrollTopBtn');
if (scrollTopBtn) {
  scrollTopBtn.addEventListener('click', (e) => {
    localScroll(document.documentElement, 0, 1000);
  });
}

/*********************
* ISSUES GRID / LIST VIEW SWITCH
*********************/

const issuesGridViewBtn = document.getElementById('issuesGridViewBtn');
const issuesListViewBtn = document.getElementById('issuesListViewBtn');
if (issuesGridViewBtn && issuesListViewBtn) {

  const issueGrid = document.querySelector('.issue-grid');
  const issueList = document.querySelector('.issue-list');

  issuesGridViewBtn.addEventListener('click', (e) => {
    e.preventDefault();
    issuesGridViewBtn.classList.add('active');
    issuesListViewBtn.classList.remove('active');
    issueGrid.classList.add('active');
    issueList.classList.remove('active');
  });

  issuesListViewBtn.addEventListener('click', (e) => {
    e.preventDefault();
    issuesListViewBtn.classList.add('active');
    issuesGridViewBtn.classList.remove('active');
    issueList.classList.add('active');
    issueGrid.classList.remove('active');
  })

}

/*********************
* LOCAL SCROLL
*********************/

const links = document.querySelectorAll('a[href*="#"]:not([href="#"]):not([href="#0"])');

links.forEach((element) => {
  const hrefUrl = new URL(element.href);

  if (window.location.hostname === hrefUrl.hostname && window.location.pathname === hrefUrl.pathname) {
    element.addEventListener("click", (e) => {
      e.preventDefault();
      const hashLink = hrefUrl.hash;
      const linkTarget = document.querySelector(hashLink);

      let headerHeight = document.querySelector('.header').offsetHeight;

      const targetTop = linkTarget.offsetTop - headerHeight;

      localScroll(document.documentElement, targetTop, 1000);

    });
  }
});

function localScroll(element, to, duration) {
  if (duration <= 0) return;

  let b = element.scrollTop;
  let c = to - element.scrollTop;

  animateLoop(element,0, b, c, duration);
}

function animateLoop(element, currentTick, b, c, d) {
  setTimeout(function() {
    if (d === currentTick * 10) return;

    let t = currentTick * 10;

    currentTick++;

    element.scrollTop = Math.floor(easeInOutQuad(t, b, c, d));

    animateLoop(element, currentTick, b, c, d);
  }, 10);
}

function easeInOutQuad(t, b, c, d) {

  // https://spicyyoghurt.com/tools/easing-functions
  // t = Current time
  // b = Begin value
  // c = Change in value
  // d = Duration

  if ((t /= d / 2) < 1) return c / 2 * t * t + b;
  return -c / 2 * ((--t) * (t - 2) - 1) + b;
}

/*********************
* ISSUE NOTES DISPLAY
*********************/

const textNotes = document.querySelectorAll('.ned-super, .eng-super');
if (textNotes) {

  const headerHeight = document.querySelector('.header').offsetHeight;
  const noteListItems = document.querySelectorAll('.note');

  const options = {
    root: null, // viewport
    threshold: 0,
    rootMargin: '-' + headerHeight + 'px 0px 0px 0px',
  };

  const animationTriggerObserver = new IntersectionObserver((entries, observer) => {
    entries.forEach((entry) => {

      let noteNumber = entry.target.getAttribute('data-notenr');
      let noteListItem = document.querySelector('[data-noteListNumber="' + noteNumber + '"]');

      if (entry.isIntersecting) {
        noteListItem.classList.add('active');
      } else {
        noteListItem.classList.remove('active');
      }
    });
  }, options);

  textNotes.forEach((element) => {

    element.addEventListener('click', (e) => {

      if (vpWidth < 1024) {

        let noteNumber = element.getAttribute('data-notenr');
        let noteListItem = document.querySelector('[data-noteListNumber="' + noteNumber + '"]');

        for (const key of noteListItems.keys()) {
          noteListItems[key].classList.remove('active');
          noteListItem.classList.add('active');
        }

        noteListItem.style.top = element.getBoundingClientRect().top + 'px';

      }

    });

    if (vpWidth >= 1024) {
      animationTriggerObserver.observe(element);
    }

    window.addEventListener('resize', (e) => {
      if (vpWidth >= 1024) {
        animationTriggerObserver.observe(element);
      } else if (vpWidth < 1024) {
        animationTriggerObserver.unobserve(element);
      }
    });

  });

  noteListItems.forEach((element) => {
    element.querySelector('.close-note-btn').addEventListener('click', (e) => {
      element.classList.remove('active');
    });

    window.addEventListener('resize', (e) => {
      if (vpWidth < 1024) {
        element.classList.remove('active');
      }
    });
  });

}

// helper function to throttle events
function throttle(callback, delay) {
  let previousCall = new Date().getTime();
  return function () {
    let time = new Date().getTime();

    if (time - previousCall >= delay) {
      previousCall = time;
      callback.apply(null, arguments);
    }
  };
}

// TODO move this to its own file and import it?
/***
 * start Reframe.js
 ***/
function reframe(target, cName) {
  var frames =
    typeof target === 'string' ? document.querySelectorAll(target) : target;
  var c = cName || 'js-reframe';
  if (!('length' in frames)) frames = [frames];
  for (var i = 0; i < frames.length; i += 1) {
    var frame = frames[i];
    var hasClass = frame.className.split(' ').indexOf(c) !== -1;
    if (hasClass || frame.style.width.indexOf('%') > -1) continue;
    var h = frame.getAttribute('height') || frame.offsetHeight;
    var w = frame.getAttribute('width') || frame.offsetWidth;
    var padding = (h / w) * 100;
    var div = document.createElement('div');
    div.className = c;
    var divStyles = div.style;
    divStyles.position = 'relative';
    divStyles.width = '100%';
    divStyles.paddingTop = padding + '%';
    var frameStyle = frame.style;
    frameStyle.position = 'absolute';
    frameStyle.width = '100%';
    frameStyle.height = '100%';
    frameStyle.left = '0';
    frameStyle.top = '0';
    frame.parentNode.insertBefore(div, frame);
    frame.parentNode.removeChild(frame);
    div.appendChild(frame);
  }
} // this gets called once dom is loaded
/* end Reframe.js */
